<template>
  <v-container
    id="manage-album-page"
    class="album-profile-page diary-page"
    v-if="is_admin"
  >
    <v-card min-height="300">
      <div>
        <v-card-title>
          <h2 class="text-h6">{{ $t("Title") }}</h2>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader :loading="is_loading" type="card" max-width="300">
            <template v-if="!error">
              <template v-if="!medias.error">
                <v-row v-if="medias && medias.items.length > 0">
                  <v-col
                    xs="6"
                    md="3"
                    cols="12"
                    v-for="item in medias.items"
                    :key="item.id"
                  >
                    <media-item :media="item"></media-item>
                  </v-col>
                </v-row>

                <v-pagination
                  class="mt-4 mb-4"
                  v-if="medias.unit_pages > 1"
                  v-model="medias.page"
                  :length="medias.unit_pages"
                  :total-visible="7"
                ></v-pagination>

                <div
                  v-if="!medias.items || medias.items.length < 1"
                  class="text-center"
                >
                  {{ $t("NoMedia") }}
                </div>
              </template>
              <error-box :error="medias.error" class="mt-4"></error-box>
            </template>
            <error-page :error="error" v-if="error"></error-page>
          </v-skeleton-loader>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "NoMedia": "There is no media to show!",
    "Title": "Photos"
    },
    "sv": {
    "NoMedia": "Det finns inga uppladdade bilder att visa!",
    "Title": "Bilder"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { albumsService } from "../../../../_services";
import MediaItem from "../../../../components/diary/helpers/ImageUploadItem.vue";

export default {
  name: "diary_album_manage",
  metaInfo() {
    return this.getMeta();
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    album: {},
    is_loading: false,
    error: null,
    breadcrumbs: [],

    medias: {
      page: 1,
      items: [],
      error: null,
      unit_pages: 1,
    },
  }),
  components: {
    "media-item": MediaItem,
  },
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }

    this.setFromUrl();
    this.loadMedias();
  },
  methods: {
    ...mapActions({
      deleteMedia: "diary/albums/deleteMedia",
    }),
    loadMedias() {
      var self = this;
      self.medias.error = null;
      self.is_loading = true;

      albumsService
        .loadMedias(self.diary.name, self.medias.page)
        .then(function (response) {
          self.medias.items = response.data.data;
          self.medias.unit_pages = response.data.unit_pages;
          self.medias.page = response.data.current_page;
          self.is_loading = false;
        })
        .catch(function (error) {
          self.medias.error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.medias.page = 1;

      if (this.$route.query.page)
        this.medias.page = parseInt(this.$route.query.page);
    },
    getMeta() {
      var obj = {
        title: this.$t("Title"),
      };
      return obj;
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadMedias();
    },
    "medias.page"() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.medias.page) return;
      self.$router.push({ query: { page: self.medias.page } });
    },
  },
};
</script>