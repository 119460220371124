<template>
  <v-container
    id="manage-album-page"
    class="album-profile-page diary-page"
    v-if="is_admin"
  >
    <v-card min-height="300">
      <v-skeleton-loader :loading="is_loading" type="article">
        <div>
          <v-card-title>
            <h2 class="text-h6">{{ album.title | capitalize }}</h2>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              :to="'/' + diary.name + '/album/' + album.url_name + '/upload'"
              v-if="album.can_upload"
              class="text-none text-subtitle-1"
            >
              <v-icon left>$vuetify.icons.plus</v-icon>
              {{ $t("UploadMedia") }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <template v-if="!error">
              <v-skeleton-loader
                :loading="medias.is_loading"
                max-width="300"
                type="card"
              >
                <template v-if="!medias.error">
                  <v-row v-if="medias && medias.items.length > 0">
                    <v-col
                      xs="6"
                      md="3"
                      cols="12"
                      v-for="item in medias.items"
                      :key="item.id"
                    >
                      <media-item
                        :media="item"
                        v-on:updated="onUpdatedInAlbum"
                        v-on:deleted="onDeletedFromAlbum"
                      ></media-item>
                    </v-col>
                  </v-row>

                  <v-pagination
                    class="mt-4 mb-4"
                    v-if="medias.unit_pages > 1"
                    v-model="medias.page"
                    :length="medias.unit_pages"
                    :total-visible="7"
                  ></v-pagination>

                  <div
                    v-if="!medias.items || medias.items.length < 1"
                    class="text-center"
                  >
                    {{ $t("NoMedia") }}
                  </div>
                </template>
                <error-box :error="medias.error" class="mt-4"></error-box>
              </v-skeleton-loader>
            </template>
            <error-page :error="error" v-if="error"></error-page>
          </v-card-text>
        </div>
      </v-skeleton-loader>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "NoMedia": "There is no media to show!",
    "BackToAlbum": "Back to album",
    "ManageMedia": "Manage media",
    "UploadMedia": "Upload images"
    },
    "sv": {
    "NoMedia": "Det finns inget uppladdat media att visa.",
    "BackToAlbum": "Tillbaka till album",
    "ManageMedia": "Hantera media",
    "UploadMedia": "Ladda upp bilder"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { albumsService } from "../../../../_services";
import MediaItem from "../../../../components/diary/helpers/ImageUploadItem.vue";

export default {
  name: "diary_album_manage",
  metaInfo() {
    return this.getMeta();
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    album: {},
    is_loading: false,
    error: null,
    breadcrumbs: [],

    medias: {
      page: 1,
      items: [],

      is_loading: false,
      error: null,
      unit_pages: 1,
    },
  }),
  components: {
    "media-item": MediaItem,
  },
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }

    this.setFromUrl();
    this.loadAlbum();
  },
  methods: {
    ...mapActions({
      deleteMedia: "diary/albums/deleteMedia",
    }),
    loadAlbum() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      var urlName = self.$route.params.name;
      var temp = self.$store.getters["diary/albums/getAlbumByUrlName"](urlName);

      if (temp) {
        self.album = temp;
        self.is_loading = false;
        self.loadMedias();
        self.createBreadcrumbs();
      } else {
        albumsService
          .loadAlbum(self.diary.name, urlName, self.medias.page)
          .then(function (response) {
            var data = response.data;

            self.album = data.album;
            self.is_loading = false;

            self.medias.items = data.medias.data;
            self.medias.unit_pages = data.medias.unit_pages;
            self.medias.page = data.medias.current_page;
            self.medias.is_loading = false;
          })
          .catch(function (error) {
            self.error = error;
            self.is_loading = false;
          });
      }
    },
    loadMedias() {
      var self = this;
      self.medias.error = null;
      self.medias.is_loading = true;

      albumsService
        .getAlbumMedia(self.album.id, self.medias.page)
        .then(function (response) {
          self.medias.items = response.data.data;
          self.medias.unit_pages = response.data.unit_pages;
          self.medias.page = response.data.current_page;
          self.medias.is_loading = false;
        })
        .catch(function (error) {
          self.medias.error = error;
          self.medias.is_loading = false;
        });
    },
    setFromUrl() {
      this.medias.page = 1;

      if (this.$route.query.page)
        this.medias.page = parseInt(this.$route.query.page);
    },
    onDeletedFromAlbum(id) {
      var self = this;
      for (var i = 0; i < this.medias.items.length; i++) {
        var obj = this.medias.items[i];
        if (obj.id == id) {
          self.medias.items.splice(i, 1);
        }
      }
    },
    onUpdatedInAlbum(image) {
      var self = this;
      for (var i = 0; i < this.medias.items.length; i++) {
        var obj = this.medias.items[i];
        if (obj.id == image.id) {
          if (image.image) obj.image = image.image;

          self.$store.dispatch("diary/timeline/reset", null, {
            root: true,
          });
        }
      }
    },
    createBreadcrumbs() {
      var self = this;
      if (self.album) {
        self.breadcrumbs.push({
          text: "<< " + self.$t("BackToAlbum"),
          disabled: false,
          exact: true,
          to: "/" + self.diary.name + "/album/" + self.album.url_name,
        });
      }
    },
    getMeta() {
      var obj = {
        title: this.$t("ManageMedia"),
      };
      return obj;
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadMedias();
    },
    "medias.page"() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.medias.page) return;
      self.$router.push({ query: { page: self.medias.page } });
    },
  },
};
</script>