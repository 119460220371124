<template>
  <v-container class="diary-admin-master diary-page" v-if="is_admin">
    <v-row class="mobile-margin-fix mt-0">
      <v-col cols="12" md="3" xs="12" class="mobile-padding-fix">
        <card-menu :title="$t('Settings')" :items="menu"></card-menu>
      </v-col>
      <v-col cols="12" md="9" xs="12" class="mobile-padding-fix">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Settings": "Settings",
    "GeneralSettings": "General",
    "PrivacySettings": "Privacy",
    "StyleSettings": "Appearance",
    "Blocking": "Blocking",
    "Menu": "Customize menu"
    },
    "sv": {
    "Settings": "Inställningar",
    "GeneralSettings": "Generella",
    "PrivacySettings": "Sekretess",
    "StyleSettings": "Utseende",
    "Blocking": "Blockering",
    "Menu": "Anpassa menyn"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import CardMenu from "@/components/helpers/menu/CardMenu.vue";

export default {
  name: "diary_admin_master",
  components: {
    "card-menu": CardMenu,
  },
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    menu: [],
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    this.menu = [
      {
        title: this.$t("GeneralSettings"),
        icon: "$vuetify.icons.fasCog",
        url: { name: "diary_admin_settings" },
        exact: true,
      },
      {
        title: this.$t("PrivacySettings"),
        icon: "$vuetify.icons.fasUserLock",
        url: { name: "diary_admin_privacysettings" },
        exact: true,
      },
      {
        title: this.$t("StyleSettings"),
        icon: "$vuetify.icons.fasPaintBrush",
        url: { name: "diary_admin_style" },
        exact: true,
      },
      {
        title: this.$t("Menu"),
        icon: "$vuetify.icons.fasTools",
        url: { name: "diary_admin_menu" },
        exact: true,
      },
      {
        title: this.$t("Blocking"),
        icon: "$vuetify.icons.fasUserLock",
        url: { name: "diary_admin_blocking" },
        exact: true,
      },
    ];
  },
  methods: {},
};
</script>