<template>
  <v-container class="album-upload-page diary-page" v-if="is_admin">
    <v-card>
      <v-skeleton-loader :loading="page.is_loading" type="article">
        <div>
          <v-card-title>
            <h2 class="text-h6">{{ $t("UploadImages") }}</h2>
            <v-spacer></v-spacer>
            <v-btn text @click="openDialog" color="primary" class="text-none text-subtitle-1" v-if="album.can_upload">
              <v-icon left>$vuetify.icons.plus</v-icon>
              {{ $t("AddImage") }}
            </v-btn>
          </v-card-title>
          <v-card-subtitle></v-card-subtitle>
          <v-card-text>
            <template v-if="!page.error && album.can_upload">
              <div class="text-center upload-tip">
                <h1>{{ album.title | capitalize }}</h1>
                <p class="caption pt-4">
                  <span v-html="$t('UploadTip')"></span>.
                  <br />
                  <a href="javascript:void(0);" @click="showSupport">{{ $t("ImageBestPractices") }}</a>
                </p>
              </div>

              <div class="mt-5">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  v-on:vdropzone-file-added="onAdded"
                  v-on:vdropzone-success="onUploaded"
                  v-on:vdropzone-thumbnail="onTumbnailCreated"
                  v-on:vdropzone-canceled="onCanceled"
                  v-on:vdropzone-error="onError"
                  v-on:vdropzone-max-files-exceeded="onMaxFilesExceeded"
                  v-on:vdropzone-upload-progress="onProcessingChange"
                  v-show="files.length == 0"
                  :useCustomSlot="true"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">{{ $t("DragDrop") }}</h3>
                    <div class="subtitle">{{ $t("OrClickSelect") }}</div>
                  </div>
                </vue-dropzone>

                <v-row v-if="files && files.length > 0">
                  <v-col xs="6" md="3" cols="12" v-for="item in files" :key="item.id">
                    <media-item
                      :media="item"
                      v-on:updated="onUpdatedInAlbum"
                      v-on:deleted="onDeletedFromAlbum"
                    ></media-item>
                  </v-col>
                </v-row>
              </div>
            </template>
            <div v-if="!page.error && !album.can_upload">{{ $t("NotUploadInAlbum") }}</div>
            <error-page :error="page.error" v-if="page.error"></error-page>
          </v-card-text>
        </div>
      </v-skeleton-loader>
    </v-card>
    <support-dialog
      ref="supportDialog"
      :title="$t('ImageBestPractices')"
      page="ImageUploadBestPractices"
    ></support-dialog>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "UploadImages": "Upload images",
    "Album": "Album",
    "BackToAlbum": "Back to album",
    "DragDrop": "Drag and drop to upload images!",
    "OrClickSelect": "...or click to select a image from your computer",
    "UploadTip": "Upload images to this album. You can upload 50 images at the same time.<br /> After a photo is uploaded, you can give it a title and description ",
    "Here": "here",
    "InvalidFileType": "Invalid file type.",
    "FileTooBig": "File is to big, {{filesize}} MB. Max filesize is {{maxFilesize}} MB.",
    "ImageBestPractices": "Image best practices",
    "ErrorUploadFile": "Error uploading file.",
    "AddImage": "Select images",
    "MediaCanceled": "Upload was canceled!",
    "MaxSelectedFiles": "You can only upload 50 images at a time.",
    "NotUploadInAlbum": "You can't upload photos in this album!",
    "PhotoUploaded": "Photo uploaded to album!"
    },
    "sv": {
    "UploadImages": "Ladda upp bilder",
    "Album": "Album",
    "BackToAlbum": "Tillbaka till album",
    "DragDrop": "Dra och släpp för att ladda upp bilder!",
    "OrClickSelect": "...eller klicka för att välja en bild från din dator",
    "UploadTip": "Ladda upp bilder i detta album. Du kan ladda upp 50 bilder åt gången.<br />När en bild har laddats upp i albumet kan du ge det en titel och en beskrivning.",
    "Here": "här",
    "InvalidFileType": "Ogiltig filtyp. Enbart bilder.",
    "FileTooBig": "Bilden är för stor, {{filesize}} MB. Maximala bildstorleken är {{maxFilesize}} MB.",
    "ImageBestPractices": "Bra att tänka på",
    "ErrorUploadFile": "Ett fel inträffade när bilden skulle laddas upp.",
    "AddImage": "Välj bilder",
    "MediaCanceled": "Uppladdningen blev avbruten!",
    "MaxSelectedFiles": "Du kan bara ladda upp 50 bilder åt gången.",
    "NotUploadInAlbum": "Du kan inte ladda upp bilder i detta album!",
    "PhotoUploaded": "Bilden sparades i albumet!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { albumsService } from "@/_services";
import { apiClient } from "@/_helpers";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import SupportDialog from "@/components/dialogs/SupportDialog.vue";
import MediaItem from "@/components/diary/helpers/ImageUploadItem.vue";

export default {
  name: "diary_album_upload",
  computed: {
    ...mapState({
      user: state => state.account.user,
      diary: state => state.diary.profile,
      is_admin: state => state.diary.is_admin
    })
  },
  metaInfo() {
    return {
      title: this.$t("UploadImages")
    };
  },
  components: {
    vueDropzone: vue2Dropzone,
    "support-dialog": SupportDialog,
    "media-item": MediaItem
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null
    },
    album: {
      title: "",
      description: ""
    },
    breadcrumbs: [],

    dropzoneOptions: {},

    files: []
  }),
  created: function() {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }

    this.loadAlbum();
  },
  methods: {
    ...mapActions({
      create: "diary/albums/create"
    }),
    loadAlbum() {
      var self = this;
      self.page.error = null;
      self.page.is_loading = true;

      var urlName = self.$route.params.name;
      var temp = self.$store.getters["diary/albums/getAlbumByUrlName"](urlName);

      if (temp) {
        self.album = temp;
        self.setDropzone();
        self.page.is_loading = false;
      } else {
        albumsService
          .getAlbumProfile(self.diary.name, urlName)
          .then(function(response) {
            self.album = response.data;
            self.setDropzone();
            self.page.is_loading = false;
          })
          .catch(function(error) {
            self.page.error = error;
            self.page.is_loading = false;
          });
      }
    },
    setDropzone() {
      var obj = this;
      this.dropzoneOptions = {
        url: process.env.VUE_APP_API_URL + "/diary/album/" + this.album.id + "/media",
        maxFilesize: 15,
        maxFiles: 50,
        resizeWidth: 1500,
        resizeHeight: 1500,
        resizeMethod: "contain",
        resizeQuality: 0.8,
        thumbnailWidth: 170,
        thumbnailHeight: 170,
        resizeMimeType: "image/jpeg",
        acceptedFiles: "image/*",
        paramName: "image",
        dictFileTooBig: obj.$t("FileTooBig"),
        dictInvalidFileType: obj.$t("InvalidFileType"),
        dictResponseError: obj.$t("ErrorUploadFile"),
        headers: {
          "X-API-Key": process.env.VUE_APP_API_KEY,
          Authorization: apiClient.getToken()
        }
      };
    },
    openDialog() {
      this.$refs.myVueDropzone.$el.click();
    },
    onAdded(file) {
      var item = {
        temp_id: this.getId(),
        name: file.name,
        image: null,
        is_uploading: true,
        error: null,
        response: null,
        progress: null
      };

      this.$set(file, "temp_id", item.temp_id);

      this.files.push(item);
    },
    onTumbnailCreated(file, dataUrl) {
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.temp_id == file.temp_id) {
          obj.is_uploading = true;
          obj.image = dataUrl;
        }
      }
    },
    onUploaded(file, response) {
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.temp_id == file.temp_id) {
          obj.is_uploading = false;
          obj.response = response;
          obj.id = response.id;
          obj.image = response.image;          
        }
      }
      this.$successNoty(this.$t("PhotoUploaded"));
      this.$store.dispatch("diary/albums/mediaUploadedInAlbum", this.album.id);
    },
    onError(file, message) {
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.temp_id == file.temp_id) {
          obj.is_uploading = false;
          obj.error = message;
        }
      }
    },
    onCanceled(file) {
      var self = this;
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.temp_id == file.temp_id) {
          obj.is_uploading = false;
          obj.error = self.$t("MediaCanceled");
        }
      }
    },
    onProcessingChange(file, progress) {
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.temp_id == file.temp_id) {
          obj.is_uploading = true;
          obj.progress = progress;
        }
      }
    },
    onMaxFilesExceeded(file) {
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.temp_id == file.temp_id) {
          obj.is_uploading = false;
          obj.error = self.$t("MaxSelectedFiles");
        }
      }
    },
    onDeletedFromAlbum(id) {
      var self = this;
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.id == id) {
          self.files.splice(i, 1);
        }
      }
    },
    onUpdatedInAlbum(image) {
      var self = this;
      for (var i = 0; i < this.files.length; i++) {
        var obj = this.files[i];
        if (obj.id == image.id) {
          if(image.image)
            obj.image = image.image;
            
          self.$store.dispatch("diary/timeline/reset", null, {
            root: true
          });
        }
      }
    },
    showSupport() {
      this.$refs.supportDialog.open();
    },
    getId() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
  },
    watch: {
    "$route.params.name": {
      handler: function (current, old) {
        if(current != old && old) {
          this.loadAlbum();
        }
      },
      deep: true,
      immediate: true,
    }
  }
};
</script>

<style scoped>
.upload-tip a {
  text-decoration: none;
}
.upload-tip a:hover {
  text-decoration: underline;
}
</style>