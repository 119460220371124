<template>
  <v-container class="diary-members-page pa-0" v-if="is_admin">
    <v-card min-height="300">
      <v-card-title>
        {{ $t("Title") }}
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="openInvite"
          class="text-none text-subtitle-1"
        >
          <v-icon left>$vuetify.icons.plus</v-icon>
          {{ $t("Invite") }}
        </v-btn>
      </v-card-title>

      <v-card-text>
        {{ $t("InviteDescription") }}
        <span class="link-color" @click="showSupport">{{
          $t("ReadMore")
        }}</span>
      </v-card-text>

      <v-card-text>
        <h3 class="text--primary">{{ $t("Members") }}</h3>
        <v-skeleton-loader
          :loading="members.is_loading"
          type="list-item-avatar"
        >
          <div>
            <v-row v-if="!members.error" no-gutters>
              <v-col
                cols="12"
                md="4"
                v-for="item in members.items"
                :key="item.username"
              >
                <v-list two-line>
                  <v-list-item class="pt-0 pb-0">
                    <v-list-item-avatar>
                      <user-avatar :user="item" :size="40"></user-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        ><router-link
                          :to="{
                            name: 'user_home',
                            params: { username: item.username },
                          }"
                          class="font-weight-bold"
                          >{{ item.username | capitalize }}</router-link
                        >
                        <span v-if="item.nickname" style="font-size: 14px">
                          ({{ item.nickname | capitalize }})</span
                        ></v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        getRole(item.roles)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        fab
                        x-small
                        elevation="0"
                        @click="editMember(item)"
                      >
                        <v-icon>$vuetify.icons.fasCog</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <error-box :error="members.error"></error-box>
          </div>
        </v-skeleton-loader>

        <div class="pb-14 pt-5">
          <h3 class="text--primary">{{ $t("SentInvites") }}</h3>
          <v-skeleton-loader
            :loading="invites.is_loading"
            type="list-item-two-line"
          >
            <div>
              <v-row v-if="!invites.error" no-gutters>
                <v-col
                  cols="12"
                  md="4"
                  v-for="item in invites.items"
                  :key="item.email"
                >
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-avatar>
                        <user-avatar :user="item" :size="40"></user-avatar>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          ><span class="font-weight-bold">{{
                            item.email
                          }}</span>
                          <span v-if="item.nickname" style="font-size: 14px">
                            ({{ item.nickname | capitalize }})</span
                          ></v-list-item-title
                        >
                        <v-list-item-subtitle
                          >{{ getRole(item.roles) }}
                          <span class="expired" v-if="item.is_expired"
                            >({{ $t("Expired") }})</span
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          fab
                          x-small
                          elevation="0"
                          @click="editInvite(item)"
                        >
                          <v-icon>$vuetify.icons.fasCog</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col class="pt-6" cols="12" v-if="invites.items.length == 0">
                  {{ $t("EmptyInvites") }}
                </v-col>
              </v-row>
              <error-box :error="invites.error"></error-box>
            </div>
          </v-skeleton-loader>
        </div>
      </v-card-text>
    </v-card>

    <support-dialog
      ref="supportDialog"
      :title="$t('InviteDiaryMembersTitle')"
      page="InviteDiaryMembers"
    ></support-dialog>

    <form-dialog
      v-model="edit_form.is_open"
      :title="$t('EditMember')"
      :is-saving="edit_form.is_saving"
      :submit-disabled="edit_form.is_deleting"
      v-on:submit="saveEditMember"
      :submit-text="$t('Button.Send')"
      width="500px"
    >
      <template v-slot:content>
        <v-form v-model="edit_form.form" ref="edit_member_form">
          <v-text-field
            v-model="edit_form.user.username"
            disabled
            outlined
            :label="$t('Username')"
          ></v-text-field>

          <v-text-field
            v-model="edit_form.user.nickname"
            outlined
            :disabled="edit_form.is_saving"
            :label="$t('Nickname')"
            :placeholder="$t('NicnameSample')"
          ></v-text-field>

          <b>{{ $t("Role") }}</b>
          <v-radio-group
            v-model="edit_form.user.role"
            row
            :disabled="
              edit_form.user.role == $enums.DIARY_ROLE.Owner ||
              edit_form.is_saving
            "
          >
            <v-radio
              :label="$t('Owner')"
              disabled
              class="mb-3"
              :value="$enums.DIARY_ROLE.Owner"
            ></v-radio>
            <v-radio
              :label="$t('Admin')"
              class="mb-3"
              :value="$enums.DIARY_ROLE.Admin"
            ></v-radio>
            <v-radio
              :label="$t('Reader')"
              class="mb-3"
              :value="$enums.DIARY_ROLE.Reader"
            ></v-radio>
          </v-radio-group>

          <div>
            <error-inline :error="edit_form.error"></error-inline>
          </div>
        </v-form>
      </template>
      <template v-slot:footer>
        <v-btn
          color="error"
          @click="deleteMember"
          :loading="edit_form.is_deleting"
          text
          :disabled="
            edit_form.is_saving ||
            edit_form.user.role == $enums.DIARY_ROLE.Owner
          "
          class="text-none"
          >{{ $t("Button.Delete") }}</v-btn
        >
      </template>
    </form-dialog>

    <form-dialog
      v-model="invite_form.is_open"
      :title="inviteFormTitle"
      :is-saving="invite_form.is_saving"
      v-on:submit="inviteMember"
      :submit-disabled="invite_form.is_deleting || invite_form.user.is_expired"
      :submit-text="invite_form.btnText"
      width="500px"
    >
      <template v-slot:content>
        <v-form v-model="invite_form.form" ref="invite_member_form">
          <v-text-field
            v-model="invite_form.user.email"
            outlined
            required
            :rules="[(v) => !!v || $t('EmailRequired')]"
            :disabled="
              invite_form.is_saving ||
              invite_form.user.id != null ||
              invite_form.is_deleting
            "
            type="email"
            :label="$t('Email')"
          ></v-text-field>

          <v-text-field
            v-model="invite_form.user.nickname"
            outlined
            :label="$t('Nickname')"
            :disabled="
              invite_form.is_saving ||
              invite_form.is_deleting ||
              invite_form.user.is_expired
            "
            :placeholder="$t('NicnameSample')"
          ></v-text-field>

          <b class="mb-n2">{{ $t("Role") }}</b>
          <v-radio-group
            v-model="invite_form.user.role"
            :disabled="
              invite_form.is_saving ||
              invite_form.is_deleting ||
              invite_form.user.is_expired
            "
            row
          >
            <v-radio
              :label="$t('Admin')"
              :value="$enums.DIARY_ROLE.Admin"
            ></v-radio>
            <v-radio
              :label="$t('Reader')"
              :value="$enums.DIARY_ROLE.Reader"
            ></v-radio>
          </v-radio-group>

          <div v-if="invite_form.user.is_expired" class="caption expired">
            {{ $t("ExpiredDesc") }}
          </div>

          <div>
            <error-inline :error="invite_form.error"></error-inline>
          </div>
        </v-form>
      </template>
      <template v-slot:footer>
        <v-btn
          v-if="invite_form.user.id"
          color="error"
          @click="deleteInvite"
          :loading="invite_form.is_deleting"
          text
          :disabled="invite_form.is_saving"
          class="text-none"
          >{{ $t("Button.Delete") }}</v-btn
        >
      </template>
    </form-dialog>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Family & Friends",
    "Owner": "Owner",
    "Admin": "Administrator",
    "Reader": "Reader",
    "Invite": "Invite",
    "InviteDiaryMembersTitle": "Invite Family & Friends",
    "EditMember": "Edit member",
    "Nickname": "Nickname",
    "Username": "Username",
    "Email": "Email",
    "EmailRequired": "Email is missing!",
    "InviteMember": "Invite member",
    "UpdateInvitedMember": "Update invite",
    "Role": "Role",
    "NicnameSample": "Mother, Dad, Sister, Friend etc..",
    "ReadMore": "Read more",
    "InviteDescription": "Invite family and friends to read and write in your diary.",
    "SuccessSendInvite": "Invite sent!",
    "SuccessUpdateMember": "Member updated!",
    "SuccessDeleteMember": "Member deleted!",
    "ConfirmDeleteMember": "Remove {username} from your diary?",
    "Members": "Diary members",
    "SentInvites": "Sent invites",
    "EmptyInvites": "There is no sent invites to show!",
    "SuccessUpdateInvite": "Invite updated!",
    "ConfirmDeleteInvite": "Remove invite for email {email}?",
    "SuccessDeleteInvite": "Invite deleted!",
    "Expired": "Expired",
    "ExpiredDesc": "The invitation has expired and can no longer be used."
    },
    "sv": {
    "Title": "Familj & Vänner",
    "Owner": "Ägare",
    "Admin": "Administratör",
    "Reader": "Läsare",
    "Invite": "Bjud in",
    "InviteDiaryMembersTitle": "Bjud in familj och vänner",
    "EditMember": "Redigera medlem",
    "Nickname": "Nickname",
    "Username": "Användarnamn",
    "Email": "Email",
    "EmailRequired": "Email saknas!",
    "InviteMember": "Skicka inbjudan",
    "UpdateInvitedMember": "Uppdatera inbjudan",
    "Role":"Roll",
    "NicnameSample": "Mor, far, syster, vän osv..",
    "ReadMore": "Läs mer",
    "InviteDescription": "Bjud in familj och vänner till att kunna läsa och skriva i din dagboken.",
    "SuccessSendInvite": "Inbjudan skickad!",
    "SuccessUpdateMember": "Medlem uppdaterad!",
    "SuccessDeleteMember": "Medlem borttagen!",
    "ConfirmDeleteMember": "Ta bort {username} som medlem från din dagbok?",
    "Members": "Dagboksmedlemmar",
    "SentInvites": "Skickade inbjudningar",
    "EmptyInvites": "Det finns inga skickade inbjudningar!",
    "SuccessUpdateInvite": "Inbjudan uppdaterades!",
    "ConfirmDeleteInvite": "Ta bort inbjudan för email, {email}?",
    "SuccessDeleteInvite": "Inbjudan togs bort!",
    "Expired": "Ej aktiv",
    "ExpiredDesc": "Inbjudan har löpt ut och går inte längre att använda."
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { membersService } from "@/_services";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import SupportDialog from "@/components/dialogs/SupportDialog.vue";

export default {
  name: "diary_blog_members",
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
    inviteFormTitle() {
      if (this.invite_form.user.id) return this.$t("UpdateInvitedMember");
      return this.$t("InviteMember");
    },
  },
  components: {
    "user-avatar": UserAvatar,
    "support-dialog": SupportDialog,
  },
  data: () => ({
    members: {
      is_loading: false,
      error: null,
      items: [],
    },
    invites: {
      is_loading: false,
      error: null,
      items: [],
    },

    edit_form: {
      is_open: false,
      is_saving: false,
      form: false,
      error: null,
      user: {
        username: "",
        role: 0,
        nickname: "",
        is_expired: false,
      },
    },

    invite_form: {
      is_open: false,
      is_saving: false,
      form: false,
      error: null,
      btnText: "",
      user: {
        email: "",
        role: 0,
        nickname: "",
        id: null,
      },
    },
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    this.loadInvites();
    this.loadMembers();
  },
  methods: {
    loadMembers() {
      var self = this;
      self.members.error = null;
      self.members.is_loading = true;

      membersService
        .members(self.diary.name)
        .then(function (response) {
          self.members.items = response.data;

          self.members.is_loading = false;
        })
        .catch(function (error) {
          self.members.error = error;
          self.members.is_loading = false;
        });
    },
    loadInvites() {
      var self = this;
      self.invites.error = null;
      self.invites.is_loading = true;

      membersService
        .invites(self.diary.name)
        .then(function (response) {
          self.invites.items = response.data;

          self.invites.is_loading = false;
        })
        .catch(function (error) {
          self.invites.error = error;
          self.invites.is_loading = false;
        });
    },
    getTitle() {
      return this.$t("Title");
    },
    getRole(roles) {
      if (!roles) return "";

      if (roles.indexOf(this.$enums.DIARY_ROLE.Owner) >= 0)
        return this.$t("Owner");
      if (roles.indexOf(this.$enums.DIARY_ROLE.Admin) >= 0)
        return this.$t("Admin");
      if (roles.indexOf(this.$enums.DIARY_ROLE.Reader) >= 0)
        return this.$t("Reader");
      return "";
    },
    getDefaultRole(roles) {
      if (!roles) return 0;

      if (roles.indexOf(this.$enums.DIARY_ROLE.Owner) >= 0)
        return this.$enums.DIARY_ROLE.Owner;
      if (roles.indexOf(this.$enums.DIARY_ROLE.Admin) >= 0)
        return this.$enums.DIARY_ROLE.Admin;
      if (roles.indexOf(this.$enums.DIARY_ROLE.Reader) >= 0)
        return this.$enums.DIARY_ROLE.Reader;
      return 0;
    },
    showSupport() {
      this.$refs.supportDialog.open();
    },

    editMember(user) {
      this.edit_form.user.username = user.username;
      this.edit_form.user.nickname = user.nickname;
      this.edit_form.user.role = this.getDefaultRole(user.roles);
      this.edit_form.is_open = true;
      this.edit_form.is_saving = false;
    },
    openInvite() {
      this.invite_form.btnText = this.$t("Button.Send");
      this.invite_form.user.email = "";
      this.invite_form.user.nickname = "";
      this.invite_form.user.role = 0;
      this.invite_form.user.id = null;
      this.invite_form.is_open = true;
      this.invite_form.is_saving = false;
      this.invite_form.user.is_expired = false;
    },
    editInvite(user) {
      this.invite_form.btnText = this.$t("Button.Save");
      this.invite_form.user.email = user.email;
      this.invite_form.user.nickname = user.nickname;
      this.invite_form.user.role = this.getDefaultRole(user.roles);
      this.invite_form.user.id = user.id;
      this.invite_form.user.is_expired = user.is_expired;
      this.invite_form.is_open = true;
      this.invite_form.is_saving = false;
    },

    saveEditMember() {
      var self = this;
      self.edit_form.error = null;

      if (self.$refs.edit_member_form.validate()) {
        self.edit_form.is_saving = true;

        membersService
          .updateMember(
            self.diary.name,
            self.edit_form.user.username,
            self.edit_form.user
          )
          .then(function () {
            self.loadMembers();
            self.edit_form.is_open = false;
            self.edit_form.is_saving = false;
            self.$successNoty(self.$t("SuccessUpdateMember"));
          })
          .catch(function (error) {
            self.edit_form.error = error;
            self.edit_form.is_saving = false;
          });
      }
    },
    async deleteMember() {
      var self = this;
      self.edit_form.error = null;

      const res = await self.$confirm(
        self.$t("ConfirmDeleteMember", {
          username: self.edit_form.user.username,
        })
      );

      if (res) {
        self.edit_form.is_deleting = true;

        membersService
          .deleteMember(self.diary.name, self.edit_form.user.username)
          .then(function () {
            self.loadMembers();
            self.edit_form.is_open = false;
            self.edit_form.is_deleting = false;
            self.$successNoty(self.$t("SuccessDeleteMember"));
          })
          .catch(function (error) {
            self.edit_form.error = error;
            self.edit_form.is_deleting = false;
          });
      }
    },
    inviteMember() {
      var self = this;
      self.invite_form.error = null;

      if (self.$refs.invite_member_form.validate()) {
        self.invite_form.is_saving = true;

        var req;

        if (self.invite_form.user.id != null)
          req = membersService.updateInvite(self.invite_form.user.id, {
            nickname: self.invite_form.user.nickname,
            role: self.invite_form.user.role,
          });
        else
          req = membersService.sendInvite(
            self.diary.name,
            self.invite_form.user
          );

        req
          .then(function () {
            self.loadInvites();
            self.invite_form.is_open = false;
            self.invite_form.is_saving = false;

            if (self.invite_form.user.id != null)
              self.$successNoty(self.$t("SuccessUpdateInvite"));
            else self.$successNoty(self.$t("SuccessSendInvite"));
          })
          .catch(function (error) {
            self.invite_form.error = error;
            self.invite_form.is_saving = false;
          });
      }
    },
    async deleteInvite() {
      var self = this;
      self.invite_form.error = null;

      const res = await self.$confirm(
        self.$t("ConfirmDeleteInvite", {
          email: self.invite_form.user.email,
        })
      );

      if (res) {
        self.invite_form.is_deleting = true;

        membersService
          .removeInvite(self.invite_form.user.id)
          .then(function () {
            self.loadInvites();
            self.invite_form.is_open = false;
            self.invite_form.is_deleting = false;
            self.$successNoty(self.$t("SuccessDeleteInvite"));
          })
          .catch(function (error) {
            self.invite_form.error = error;
            self.invite_form.is_deleting = false;
          });
      }
    },
  },
};
</script>

<style scoped>
a.font-weight-bold {
  text-decoration: none;
  color: #000000de;
}
a.font-weight-bold:hover {
  text-decoration: underline;
}
.expired {
  color: red;
}
</style>