<template>
  <div>
    <v-card-text>
      <v-skeleton-loader :loading="page.is_loading" type="article">
        <div>
          <template v-if="!page.error">
            <div v-if="!items || items.length < 1" class="text-center pt-15">
              <span v-if="$route.params.status == 'pending'">{{
                $t("NoCommentsPending")
              }}</span>
              <span v-if="$route.params.status == 'abused'">{{
                $t("NoCommentsAbused")
              }}</span>
              <span v-if="$route.params.status == 'spam'">{{
                $t("NoCommentsSpam")
              }}</span>
            </div>

            <template v-if="items && items.length > 0">
              <v-timeline align-top dense class="ml-n7">
                <v-timeline-item
                  right
                  class="comment-item"
                  v-for="comment in items"
                  :key="comment.id"
                >
                  <template v-slot:icon>
                    <router-link :to="'/user/' + comment.user.username">
                      <user-avatar
                        :user="comment.user"
                        :size="50"
                      ></user-avatar>
                    </router-link>
                  </template>
                  <v-card outlined>
                    <v-card-title class="subtitle-2 pt-2 pb-2 grey lighten-2">
                      <router-link
                        :to="'/user/' + comment.user.username"
                        class="pr-2"
                        >{{ comment.user.username | capitalize }}</router-link
                      >
                      <span class="caption pr-2 grey--text text--darken-2">{{
                        sinceTime(comment.create_date)
                      }}</span>
                      <v-spacer></v-spacer>
                      <span class="caption grey--text text--darken-2"
                        >Ip: {{ comment.ip }}</span
                      >
                    </v-card-title>
                    <v-card-text v-if="comment.post" class="caption pb-0">
                      {{ $t("CommentOnPost") }}:
                      <router-link
                        :to="
                          '/' + diary.name + '/diary/' + comment.post.url_name
                        "
                        >{{
                          getTitle(comment.post.header) | capitalize
                        }}</router-link
                      >
                    </v-card-text>
                    <v-card-text v-if="comment.media" class="caption pb-0">
                      {{ $t("CommentOnMedia") }}:
                      <router-link
                        :to="
                          '/' + diary.name + '/album/media/' + comment.media.id
                        "
                        >{{
                          getTitle(comment.media.title) | capitalize
                        }}</router-link
                      >
                    </v-card-text>
                    <v-card-text
                      v-if="!comment.media && !comment.post"
                      class="caption pb-0"
                    >
                      {{ $t("CommentIn") }}:
                      <router-link :to="'/' + diary.name + '/guestbook'">{{
                        $t("Guestbook")
                      }}</router-link>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="pt-4">
                      <div class="comment-text text--primary">
                        <render-string :string="getText(comment.text)" />
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-tooltip top v-if="comment.is_spam">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" class="ml-1" small color="red"
                            >$vuetify.icons.fasExclamationTriangle</v-icon
                          >
                        </template>
                        <span>{{ $t("SpamReportedDesc") }}</span>
                      </v-tooltip>

                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="!comment.is_approved"
                        :loading="comment.is_approving"
                        :disabled="comment.is_deleting"
                        text
                        small
                        @click="accept(comment)"
                        class="text-none"
                        color="success"
                        >{{ $t("Approve") }}</v-btn
                      >

                      <v-btn
                        v-if="type == 'abused'"
                        :loading="comment.is_keeping"
                        :disabled="comment.is_deleting"
                        text
                        small
                        @click="unabuse(comment)"
                        class="text-none"
                        color="success"
                        >{{ $t("Keep") }}</v-btn
                      >
                      <v-btn
                        v-if="type == 'spam'"
                        :loading="comment.is_keeping"
                        :disabled="comment.is_deleting"
                        text
                        small
                        @click="unspam(comment)"
                        class="text-none"
                        color="success"
                        >{{ $t("Keep") }}</v-btn
                      >

                      <v-btn
                        :disabled="comment.is_approving"
                        :loading="comment.is_deleting"
                        text
                        small
                        @click="deleteComment(comment)"
                        class="text-none"
                        color="red"
                        >{{ $t("Button.Delete") }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-timeline-item>
              </v-timeline>

              <div class="text-center pt-5 pb-10" v-if="unit_pages > 1">
                <v-btn
                  :loading="page.is_loading"
                  @click="load"
                  class="text-none"
                  >{{ $t("LoadMore") }}</v-btn
                >
              </div>
            </template>
          </template>
          <error-box :error="page.error"></error-box>
        </div>
      </v-skeleton-loader>
    </v-card-text>
  </div>
</template>

<i18n>
    {
    "en": {
    "Title": "Comments",
    "All": "All",
    "Pending": "Pending",
    "Accepted": "Accepted",
    "Abused": "Abused",
    "Spam": "Spam",
    "NoComments": "No comments to show.",
    "Approve": "Approve",
    "SuccessAcceptComment": "Comment approved!",
    "SuccessDeleteComment": "Comment deleted!",
    "SpamReportedDesc": "The system has marked this comment as spam.",
    "ConfirmDeleteComment": "Are you sure you want to delete this comment?",
    "Guestbook": "Guestbook",
    "CommentOnMedia": "Comment on media",
    "CommentOnPost": "Comment on post",
    "CommentIn": "Comment in",
    "LoadMore": "Load more",
    "TitleMissing": "Title is missing",
    "Keep": "Keep",
    "DiaryPost": "Diary posts",
    "Photos": "Photos",
    "NoCommentsPending": "No comments to accept!",
    "NoCommentsAbused": "No abused comments!",
    "NoCommentsSpam": "No comments marked as spam!"
    },
    "sv": {
    "Title": "Kommentarer",
    "All": "Alla",
    "Pending": "Väntande",
    "Accepted": "Godkänd",
    "Abused": "Anmälda",
    "Spam": "Skräppost",
    "NoComments": "Inga kommentarer ännu.",
    "Approve": "Acceptera",
    "SuccessAcceptComment": "Kommentaren accepterades!",
    "SuccessDeleteComment": "Kommentaren togs bort!",
    "SpamReportedDesc": "Systemet har markerat denna kommentar som skräppost.",
    "ConfirmDeleteComment": "Är du säker på att du vill ta bort denna kommentar?",
    "Guestbook": "Gästboken",
    "CommentOnMedia": "Kommentar på bilden",
    "CommentOnPost": "Kommentar på dagboksinlägget",
    "CommentIn": "Kommentar i",
    "LoadMore": "Ladda fler",
    "TitleMissing": "Rubrik saknas",
    "Keep": "Behåll",
    "DiaryPost": "Dagboksinlägg",
    "Photos": "Bilder",
    "NoCommentsPending": "Inga kommentarer att godkänna!",
    "NoCommentsAbused": "Inga anmälda kommentarer!",
    "NoCommentsSpam": "Inga kommentarer har markerats som skräppost!"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { commentsService } from "@/_services";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_admin_comments",
  metaInfo() {
    return {
      title: this.$t("Title"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "user-avatar": UserAvatar,
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },
    current_page: 0,
    unit_pages: 0,

    items: [],
    filter: {},
    status: null,
    type: null,
  }),
  created: function () {
    this.load();
  },
  methods: {
    sinceTime: dateHelpers.timeSince,
    load() {
      var self = this;
      self.page.error = null;
      self.page.is_loading = true;

      if (!self.current_page) self.current_page = 0;

      var type = self.$route.query.type;

      if (type == "guestbook") type = 1;
      else if (type == "diary") type = 2;
      else if (type == "photos") type = 3;
      else type = null;

      var data = {
        page: self.current_page + 1,
        pagesize: 30,
        type: type,
      };

      this.status = self.$route.params.status;

      if (!this.status) this.status = "pending";

      if (this.status) {
        if (this.status == "pending") {
          data["is_approved"] = false;
        } else if (this.status == "accepted") {
          data["is_approved"] = true;
        } else if (this.status == "abused") {
          data["is_abused"] = true;
        } else if (this.status == "spam") {
          data["is_spam"] = true;
        }
      }

      commentsService
        .search(self.diary.name, data)
        .then(function (response) {
          self.setData(response);
        })
        .catch(function (error) {
          self.setError(error);
        });
    },

    setData(response) {
      this.page.is_loading = false;
      this.current_page = response.data.current_page;
      this.unit_pages = response.data.unit_pages;
      this.items = this.items.concat(response.data.data);
    },
    setError(err) {
      this.page.is_loading = false;
      this.page.error = err;
    },
    getText(text) {
      return text
        .escapeHtml()
        .parseUsername()
        .replace("<a", "<router-link")
        .replace("href=", "to=")
        .replace("</a>", "</router-link>");
    },
    getTitle(title) {
      if (!title) {
        return this.$t("TitleMissing");
      }
      return title;
    },

    accept(comment) {
      var self = this;
      self.$set(comment, "is_approving", true);

      commentsService
        .approve(comment.id)
        .then(function () {
          self.$successNoty(self.$t("SuccessAcceptComment"));

          comment.is_approved = true;

          if (self.$route.params.type == "pending")
            self.removeFromList(comment.id);
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          comment.is_approving = false;
        });
    },
    unabuse(comment) {
      var self = this;
      self.$set(comment, "is_keeping", true);

      commentsService
        .unabuse(comment.id)
        .then(function () {
          self.$successNoty(self.$t("SuccessAcceptComment"));

          comment.is_abused = true;

          if (self.status == "abused") self.removeFromList(comment.id);
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          comment.is_approving = false;
        });
    },
    unspam(comment) {
      var self = this;
      self.$set(comment, "is_keeping", true);

      commentsService
        .unspam(comment.id)
        .then(function () {
          self.$successNoty(self.$t("SuccessAcceptComment"));

          comment.is_spam = true;

          if (self.status == "spam") self.removeFromList(comment.id);
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          comment.is_approving = false;
        });
    },
    async deleteComment(comment) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteComment"));

      if (res) {
        self.$set(comment, "is_deleting", true);
        commentsService
          .delete(comment.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDeleteComment"));
            self.removeFromList(comment.id);
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            comment.is_deleting = false;
          });
      }
    },
    removeFromList(id) {
      for (var i = 0; i < this.items.length; i++) {
        var obj = this.items[i];

        if (obj.id == id) {
          this.items.splice(i, 1);
        }
      }
    },
  },
  watch: {
    $route() {
      this.current_page = 0;
      this.unit_pages = 0;
      this.items = [];
      this.load();
    },
    comment_list_type: function () {
      this.current_page = 0;
      this.unit_pages = 0;
      this.items = [];
      this.load();
    },
  },
};
</script>

<style scoped>
.comment-item .v-card__title a,
.comment-item .v-card__text.caption a {
  color: black;
  text-decoration: none;
}
.comment-item .v-card__title a:hover,
.comment-item .v-card__text.caption a:hover {
  text-decoration: underline;
}

.filter {
  font-size: 12px;
  font-weight: bold;
}
</style>