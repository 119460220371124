<template>
  <v-container class="diary-admin-master diary-page" v-if="is_admin">
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "diary_admin_master",
  components: {},
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    menu: [],
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
  },
  methods: {},
};
</script>