<template>
  <v-container class="pa-0" v-if="is_admin">
    <v-card min-height="300" class="pb-10">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("Background") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-skeleton-loader :loading="page.is_loading" type="article">
          <div>
            <template v-if="!page.error">
              <v-form
                ref="background_form"
                v-model="background.form"
                lazy-validation
              >
                <v-card flat class="mb-5">
                  <div class="caption">{{ $t("BackgroundImage") }}</div>
                  <div class="d-flex flex-no-wrap">
                    <v-avatar
                      size="200"
                      @click="pickBackground"
                      style="border: solid 1px gray !important"
                      tile
                      class="grey lighten-4"
                    >
                      <v-img
                        v-if="styles_background.background_image"
                        :src="styles_background.background_image"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>

                      <v-fade-transition>
                        <v-overlay
                          v-if="background_file.is_loading"
                          absolute
                          color="#036358"
                        >
                          <v-progress-circular
                            indeterminate
                            size="64"
                          ></v-progress-circular>
                        </v-overlay>
                      </v-fade-transition>
                    </v-avatar>
                    <input
                      type="file"
                      style="display: none"
                      ref="image"
                      accept="image/*"
                      v-on:change="onBackgroundPicked"
                    />

                    <div>
                      <v-card-title>
                        <button-menu
                          small
                          outlined
                          :items="background_menu"
                          btn-icon-append="$vuetify.icons.menuDown"
                          :btn-text="$t('ChangeBackground')"
                          class="mt-14"
                          :disabled="background.is_saving"
                        ></button-menu>
                      </v-card-title>
                      <div
                        class="error-text caption ml-4"
                        v-if="background_file.error"
                      >
                        {{ background_file.error }}
                      </div>
                    </div>
                  </div>
                </v-card>

                <v-select
                  :items="background.repeat_types"
                  item-text="text"
                  item-value="value"
                  outlined
                  class="mt-3"
                  v-model="styles_background.background_image_repeat"
                  :disabled="background.is_saving"
                  :label="$t('BackgroundRepeat')"
                ></v-select>

                <v-text-field
                  :label="$t('BackgroundColor')"
                  :disabled="background.is_saving"
                  outlined
                  v-model="styles_background.background_color"
                >
                  <template v-slot:prepend>
                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          :style="
                            'background-color:' +
                            styles_background.background_color
                          "
                          outlined
                          tile
                          icon
                          height="53px"
                          width="50px"
                          class="mt-n4"
                        ></v-btn>
                      </template>
                      <v-color-picker
                        v-model="styles_background.background_color"
                        mode="hexa"
                        hide-inputs
                      ></v-color-picker>
                    </v-menu>
                  </template>
                </v-text-field>

                <error-box :error="background.error"></error-box>
                <v-card-actions>
                  <v-btn
                    :disabled="background.is_saving"
                    text
                    color="primary"
                    class="text-none"
                    @click="previewStyle"
                  >
                    <v-icon left>$vuetify.icons.fasSearch</v-icon>
                    {{ $t("Preview") }}
                  </v-btn>
                  <v-spacer></v-spacer>

                  <span
                    v-if="background.is_success"
                    class="success-text mr-4"
                    >{{ $t("Saved") }}</span
                  >
                  <v-btn
                    :loading="background.is_saving"
                    color="primary"
                    @click="saveBackground"
                    class="text-none"
                    >{{ $t("Button.Save") }}</v-btn
                  >
                </v-card-actions>
              </v-form>
              <media-picker
                v-on:done="selectedMedia"
                ref="mediaPicker"
                :diary="diary.name"
                :max="1"
              ></media-picker>
            </template>
            <error-page :error="page.error" v-if="page.error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Background": "Background style",
    "BackgroundImage": "Background image",
    "BackgroundColor": "Background color",
    "BackgroundRepeat": "How to place the background image",
    "Saved": "Saved",
    "UploadImage": "Select image",
    "SelectFromAlbum": "Select from my album",
    "ChangeBackground": "Change image",
    "Repeat": "Repeat",
    "RepeatH": "Repeat horizontally",
    "RepeatHC": "Repeat horizontally centrally",
    "RepeatHR": "Repeat horizontally right",
    "RepeatV": "Repeat vertically",
    "NoRepeat": "Do not repeat",
    "NoRepeatV": "Do not repeat, left",
    "NoRepeatR": "Do not repeat, right",
    "Cover": "Cover",
    "Preview": "Preview",
    "Title": "Appearance settings",
    "Desc": "Customize the look of your diary. <br /> Select a background image by uploading one or select one from your photo albums. Then choose how you want the image to be placed and repeated on the page. <br /> You also have the option to specify a background color for your diary."
    },
    "sv": {
    "Background": "Backgrunds inställningar",
    "BackgroundImage": "Bakgrundsbild",
    "BackgroundColor": "Bakgrundsfärg",
    "BackgroundRepeat": "Hur ska bakgrundsbilden placeras",
    "Saved": "Sparat",
    "UploadImage": "Välj bild",
    "SelectFromAlbum": "Välj från mina album",
    "ChangeBackground": "Byt bild",
    "Repeat": "Upprepa",
    "RepeatH": "Upprepa horisontellt",
    "RepeatHC": "Upprepa horisontellt centralt",
    "RepeatHR": "Upprepa horisontellt höger",
    "RepeatV": "Upprepa vertikalt",
    "NoRepeat": "Upprepa ej",
    "NoRepeatV": "Upprepa ej, vänsterställd",
    "NoRepeatR": "Upprepa ej, högerställd",
    "Cover": "Omslagsbild",
    "Preview": "Förhandsgranska",
    "Title": "Anpassa utseende",
    "Desc": "Anpassa utseende på din dagbok.<br />Välj en bakgrundsbild genom att ladda upp en eller välj en från dina fotoalbum. Välj sedan hur du vill att bilden ska placeras och upprepas på sidan.<br />Du har även möjlighet att ange en bakgrundsfärg som din dagbok ska ha."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import MediaPicker from "@/components/diary/dialogs/MediaPickerDialog.vue";
import Compressor from "compressorjs";

export default {
  name: "diary_background_settings",
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      styles: (state) => state.diary.styles,
      is_admin: (state) => state.diary.is_admin,
    }),
    background_menu: function () {
      var data = [];

      data.push({
        name: this.$t("UploadImage"),
        icon: "$vuetify.icons.cloudUploadOutline",
        click: this.pickBackground,
      });

      data.push({
        name: this.$t("SelectFromAlbum"),
        icon: "$vuetify.icons.imageMultipleOutline",
        click: this.showMediaPicker,
      });

      if (this.styles_background.background_image)
        data.push({
          name: this.$t("Button.Delete"),
          icon: "$vuetify.icons.trashCanOutline",
          click: this.deleteBackground,
        });

      return data;
    },
  },
  components: {
    "media-picker": MediaPicker,
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },
    background: {
      is_saving: false,
      error: null,
      form: false,
      is_success: false,
      repeat_types: [],
    },
    styles_background: {
      background_color: "",
      background_image_repeat: "",
      background_image: "",
    },

    background_file: {
      file: null,
      is_loading: false,
      error: null,
    },
  }),
  created: function () {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("diary/resetTemp");

    next();
  },
  methods: {
    ...mapActions({
      updateBackgroundStyle: "diary/updateBackgroundStyle",
    }),
    load() {
      var self = this;

      if (self.styles && self.styles.background) {
        var background = self.styles.background;

        if (background.background_color)
          self.styles_background.background_color = background.background_color;

        if (background.background_image_repeat)
          self.styles_background.background_image_repeat =
            background.background_image_repeat;

        if (background.background_image)
          self.styles_background.background_image = background.background_image;
      }

      self.background.repeat_types = [
        { text: self.$t("Repeat"), value: "R" },
        { text: self.$t("RepeatH"), value: "H" },
        { text: self.$t("RepeatHC"), value: "HC" },
        { text: self.$t("RepeatHR"), value: "HR" },
        { text: self.$t("RepeatV"), value: "V" },
        { text: self.$t("NoRepeat"), value: "0" },
        { text: self.$t("NoRepeatV"), value: "0l" },
        { text: self.$t("NoRepeatR"), value: "0r" },
        { text: self.$t("Cover"), value: "C" },
      ];
    },
    pickBackground() {
      this.$refs.image.click();
    },
    onBackgroundPicked(e) {
      var self = this;
      var bgfile = self.background_file;
      const file = e.target.files[0];

      bgfile.error = null;

      if (!file) {
        return;
      }
      bgfile.is_loading = true;

      new Compressor(file, {
        quality: 0.8,
        minWidth: 100,
        minHeight: 100,
        maxHeight: 2000,
        maxWidth: 2000,
        success(response) {
          bgfile.file = response;
          bgfile.image_id = null;

          var urlCreator = window.URL || window.webkitURL;
          self.styles_background.background_image =
            urlCreator.createObjectURL(response);

          bgfile.is_loading = false;
        },
        error(error) {
          bgfile.error = error;
          bgfile.is_loading = false;
        },
      });
    },
    showMediaPicker() {
      this.$refs.mediaPicker.open();
    },
    selectedMedia(images) {
      var self = this;
      var bgfile = self.background_file;

      bgfile.error = null;

      if (!images || images.length != 1) {
        return;
      }
      bgfile.is_loading = true;

      var img = images[0];
      bgfile.image_id = img.id;

      self.$imagehelpers
        .getImageFormUrl(img.image.original)
        .then(function (response) {
          bgfile.file = response;

          var urlCreator = window.URL || window.webkitURL;
          self.styles_background.background_image =
            urlCreator.createObjectURL(response);

          bgfile.is_loading = false;
        })
        .catch(function (error) {
          bgfile.error = error;
          bgfile.is_loading = false;
        });
    },
    deleteBackground() {
      this.styles_background.background_image = null;
      this.background_file.file = null;
      this.background_file.image_id = null;
    },
    saveBackground() {
      var self = this;
      self.background.is_saving = false;
      self.background.is_success = false;
      self.background.error = null;

      if (self.$refs.background_form.validate()) {
        self.background.is_saving = true;

        if (
          !self.styles_background.background_color ||
          self.styles_background.background_color == ""
        ) {
          self.styles_background.background_color = "#FFFFFF";
        }

        self
          .updateBackgroundStyle({
            data: self.styles_background,
            file: self.background_file.file,
            image_id: self.background_file.image_id,
          })
          .then(function () {
            self.background.is_saving = false;
            self.background.is_success = true;
            self.background_file.file = null;
            self.background_file.image_id = null;
          })
          .catch(function (error) {
            self.background.error = error;
            self.background.is_saving = false;
          });
      }
    },
    previewStyle() {
      var self = this;
      this.$store.commit("diary/setTempData", { ...self.styles_background });
    },
    getTitle() {
      return this.$t("Title");
    },
  },
};
</script>