<template>
  <v-container class="diary-categories-page pa-0" v-if="is_admin">
    <v-skeleton-loader :loading="page.is_loading" type="article">
      <v-row class="mobile-margin-fix mt-0" v-if="!page.error">
        <v-col cols="12" md="3" xs="12" class="mobile-padding-fix">
          <card-menu :title="$t('Title')" :items="menu"></card-menu>
        </v-col>
        <v-col cols="12" md="9" xs="12" class="mobile-padding-fix">
          <v-card min-height="300" elevation="1">
            <v-card-title>
              <span v-if="$route.params.status == 'pending'">{{
                $t("Pending")
              }}</span>
              <span v-if="$route.params.status == 'abused'">{{
                $t("Abused")
              }}</span>
              <span v-if="$route.params.status == 'spam'">{{
                $t("Spam")
              }}</span>
              <v-spacer></v-spacer>

              <button-menu
                depressed
                :items="menu_types"
                btn-icon="$vuetify.icons.faFilter"
                btn-icon-small
                :btn-text="$t('Filter')"
              ></button-menu>
            </v-card-title>

            <router-view :key="$route.fullPath"></router-view>
          </v-card>
        </v-col>
      </v-row>
      <error-page :error="page.error"></error-page>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Comments",
    "Pending": "Pending",
    "Abused": "Abused",
    "Spam": "Spam",
    "Filter": "Filter",
    "Guestbook": "Guestbook",
    "Diary": "Diary",
    "Photos": "Photos",
    "All": "All"
    },
    "sv": {
    "Title": "Kommentarer",
    "Pending": "Väntande",
    "Abused": "Anmälda",
    "Spam": "Skräppost",
    "Filter": "Filter",
    "Guestbook": "Gästboken",
    "Diary": "Dagboken",
    "Photos": "Bilder",
    "All": "Alla"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { commentsService } from "@/_services";
import CardMenu from "@/components/helpers/menu/CardMenu.vue";

export default {
  name: "diary_admin_comments_master",
  metaInfo() {
    return {
      title: this.$t("Title"),
    };
  },
  components: {
    "card-menu": CardMenu,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },
    menu: [],
    menu_types: [],
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }

    this.loadStatistics();

    this.menu_types = [
      {
        name: this.$t("All"),
        to: {
          name: "diary_admin_comments_status",
        },
        exact: true,
      },
      {
        name: this.$t("Guestbook"),
        to: {
          name: "diary_admin_comments_status",
          query: { type: "guestbook" },
        },
        exact: true,
      },
      {
        name: this.$t("Photos"),
        to: {
          name: "diary_admin_comments_status",
          query: { type: "photos" },
        },
        exact: true,
      },
      {
        name: this.$t("Diary"),
        to: {
          name: "diary_admin_comments_status",
          query: { type: "diary" },
        },
        exact: true,
      },
    ];
  },
  methods: {
    loadStatistics() {
      var self = this;
      self.page.is_loading = true;

      commentsService
        .statistics(self.diary.name)
        .then(function (response) {
          self.setMenu(response.data);
          self.page.is_loading = false;
        })
        .catch(function (error) {
          self.setError(error);
        });
    },
    setError(err) {
      this.page.is_loading = false;
      this.page.error = err;
    },

    setMenu(statistics) {
      this.menu = [
        {
          title: this.$t("Pending"),
          badge: this.getFormatedUnit(statistics.unit_pending),
          url: {
            name: "diary_admin_comments_status",
            params: { status: "pending" },
          },
        },
        {
          title: this.$t("Abused"),
          badge: this.getFormatedUnit(statistics.unit_abused),
          url: {
            name: "diary_admin_comments_status",
            params: { status: "abused" },
          },
        },
        {
          title: this.$t("Spam"),
          badge: this.getFormatedUnit(statistics.unit_spam),
          url: {
            name: "diary_admin_comments_status",
            params: { status: "spam" },
          },
        },
      ];
    },

    getFormatedUnit(value) {
      if (!value) return "0";
      if (value > 99) return "99+";
      else return value;
    },
    getColor(value) {
      if (value > 0) return "orange darken-3";
      return "grey";
    },
  },
};
</script>