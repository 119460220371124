var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.is_admin)?_c('v-container',{staticClass:"pa-0"},[_c('v-card',{staticClass:"pb-10",attrs:{"min-height":"300"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("PrivacySettings")))])],1),_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"loading":_vm.page.is_loading,"type":"article"}},[_c('div',[(!_vm.page.error)?[_c('div',{staticClass:"mb-7",domProps:{"innerHTML":_vm._s(_vm.$t('Desc'))}}),_c('v-form',{ref:"privacy_form",attrs:{"lazy-validation":""},model:{value:(_vm.privacy.form),callback:function ($$v) {_vm.$set(_vm.privacy, "form", $$v)},expression:"privacy.form"}},[_c('v-select',{attrs:{"items":[
                  {
                    text: _vm.$t('OnlyForMe'),
                    value: _vm.$enums.DIARY_PRIVACY.OnlyForMe,
                  },
                  {
                    text: _vm.$t('OpenForAll'),
                    value: _vm.$enums.DIARY_PRIVACY.OpenForAll,
                  },
                  {
                    text: _vm.$t('PasswordProtected'),
                    value: _vm.$enums.DIARY_PRIVACY.PasswordProtected,
                  },
                  {
                    text: _vm.$t('OnlyForMembers'),
                    value: _vm.$enums.DIARY_PRIVACY.OnlyForMembers,
                  } ],"disabled":_vm.privacy.is_saving,"required":"","outlined":"","label":_vm.$t('Availability')},model:{value:(_vm.settings.viewable),callback:function ($$v) {_vm.$set(_vm.settings, "viewable", $$v)},expression:"settings.viewable"}}),(_vm.settings.viewable == 2)?_c('div',[_c('v-text-field',{attrs:{"label":_vm.$t('Password'),"disabled":_vm.privacy.is_saving,"outlined":"","append-icon":_vm.privacy.password_visibility
                      ? '$vuetify.icons.eye'
                      : '$vuetify.icons.eyeOff',"type":_vm.privacy.password_visibility ? 'text' : 'password',"rules":[function (v) { return !!v || _vm.$t('PasswordIsMissing'); }],"required":""},on:{"click:append":function () { return (_vm.privacy.password_visibility =
                        !_vm.privacy.password_visibility); }},model:{value:(_vm.settings.password),callback:function ($$v) {_vm.$set(_vm.settings, "password", $$v)},expression:"settings.password"}})],1):_vm._e(),_c('error-box',{attrs:{"error":_vm.privacy.error}}),_c('v-card-actions',[_c('v-spacer'),(_vm.privacy.is_success)?_c('span',{staticClass:"success-text mr-4"},[_vm._v(_vm._s(_vm.$t("Saved"))+"!")]):_vm._e(),_c('v-btn',{staticClass:"text-none",attrs:{"loading":_vm.privacy.is_saving,"color":"primary"},on:{"click":_vm.savePrivacy}},[_vm._v(_vm._s(_vm.$t("Button.Save")))])],1)],1)]:_vm._e(),(_vm.page.error)?_c('error-page',{attrs:{"error":_vm.page.error}}):_vm._e()],2)])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }