<template>
  <v-container class="pa-0" v-if="is_admin">
    <v-card min-height="300" class="pb-10">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("PrivacySettings") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-skeleton-loader :loading="page.is_loading" type="article">
          <div>
            <template v-if="!page.error">
              <div class="mb-7" v-html="$t('Desc')"></div>
              <v-form ref="privacy_form" v-model="privacy.form" lazy-validation>
                <v-select
                  v-bind:items="[
                    {
                      text: $t('OnlyForMe'),
                      value: $enums.DIARY_PRIVACY.OnlyForMe,
                    },
                    {
                      text: $t('OpenForAll'),
                      value: $enums.DIARY_PRIVACY.OpenForAll,
                    },
                    {
                      text: $t('PasswordProtected'),
                      value: $enums.DIARY_PRIVACY.PasswordProtected,
                    },
                    {
                      text: $t('OnlyForMembers'),
                      value: $enums.DIARY_PRIVACY.OnlyForMembers,
                    },
                  ]"
                  v-model="settings.viewable"
                  :disabled="privacy.is_saving"
                  required
                  outlined
                  :label="$t('Availability')"
                ></v-select>

                <div v-if="settings.viewable == 2">
                  <v-text-field
                    :label="$t('Password')"
                    v-model="settings.password"
                    :disabled="privacy.is_saving"
                    outlined
                    :append-icon="
                      privacy.password_visibility
                        ? '$vuetify.icons.eye'
                        : '$vuetify.icons.eyeOff'
                    "
                    @click:append="
                      () =>
                        (privacy.password_visibility =
                          !privacy.password_visibility)
                    "
                    :type="privacy.password_visibility ? 'text' : 'password'"
                    :rules="[(v) => !!v || $t('PasswordIsMissing')]"
                    required
                  ></v-text-field>
                </div>

                <error-box :error="privacy.error"></error-box>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <span v-if="privacy.is_success" class="success-text mr-4"
                    >{{ $t("Saved") }}!</span
                  >
                  <v-btn
                    :loading="privacy.is_saving"
                    color="primary"
                    @click="savePrivacy"
                    class="text-none"
                    >{{ $t("Button.Save") }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </template>
            <error-page :error="page.error" v-if="page.error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "PrivacySettings": "Privacy settings",
    "SuccessSaveSettings": "Privacy updated!",
    "Password": "Password",
    "PasswordIsMissing": "Password is missing!",
    "Availability": "Availability",
    "OnlyForMe": "Only for me",
    "OpenForAll": "Open for all",
    "PasswordProtected": "Password protected",
    "OnlyForMembers": "Only open for members",
    "FieldRequired": "Required",
    "Saved": "Saved",
    "PreModerateComments": "Pre-moderate all comments and guestbook posts",
    "Desc": "Control which visitors should be able to read your diary. The password you enter is saved encrypted but can be decrypted and retrieved to be displayed in the box on this page."
    },
    "sv": {
    "PrivacySettings": "Sekretessinställningar",
    "SuccessSaveSettings": "Inställningarna uppdaterades!",
    "Password": "Lösenord",
    "PasswordIsMissing": "Lösenord måste anges!",
    "Availability": "Tillgänglig",
    "OnlyForMe": "Enbart öppen för mig",
    "OpenForAll": "Öppen för alla",
    "PasswordProtected": "Lösenord krävs för att komma in",
    "OnlyForMembers": "Enbart öppen för inloggade besökare",
    "FieldRequired": "Måste anges",
    "Saved": "Sparat",
    "PreModerateComments": "Förhandsgranska alla kommentarer och gästboksinlägg",
    "Desc": "Styr över vilka besökare som ska kunna läsa din dagbok. Lösenordet du anger sparas krypterat men går att dekryptera och få fram igen för att kunna visas i rutan på denna sida."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { diaryService } from "../../../../_services";

export default {
  name: "diary_settings_page",
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },

    settings: {},

    privacy: {
      form: true,
      is_saving: false,
      error: null,
      password_visibility: false,
      is_success: false,
    },
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    this.load();
  },
  methods: {
    ...mapActions({
      updatePrivacy: "diary/updatePrivacy",
    }),
    getTitle() {
      return this.$t("PrivacySettings");
    },
    load() {
      var self = this;
      self.page.is_loading = true;

      diaryService
        .loadAdminDiary(self.diary.name)
        .then(function (response) {
          self.settings = response.data;
          self.page.is_loading = false;
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },
    savePrivacy() {
      var self = this;
      self.privacy.error = null;
      self.privacy.is_success = false;

      if (self.$refs.privacy_form.validate()) {
        self.privacy.is_saving = true;

        self
          .updatePrivacy({
            viewable: self.settings.viewable,
            password: self.settings.password,
          })
          .then(function () {
            self.privacy.is_saving = false;
            self.privacy.is_success = true;
          })
          .catch(function (error) {
            self.privacy.error = error;
            self.privacy.is_saving = false;
          });
      }
    },
  },
};
</script>