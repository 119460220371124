<template>
  <v-container class="diary-form-page diary-page">
    <v-card>
      <v-card-title>{{ $t("CreatePost") }}</v-card-title>
      <v-card-text> <post-form></post-form></v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "CreatePost": "Create post"
    },
    "sv": {
    "CreatePost": "Nytt inlägg"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import Form from "../../../../components/diary/admin/BlogPostForm.vue";
export default {
  name: "diary_blog_new",
  metaInfo() {
    return {
      title: this.$t("CreatePost"),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "post-form": Form,
  },
  data: () => ({}),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
  },
  methods: {},
};
</script>