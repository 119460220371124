<template>
  <v-container class="pa-0" v-if="is_admin">
    <v-card min-height="300" class="pb-10">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("Title") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-skeleton-loader
          :loading="is_loading"
          transition="scale-transition"
          type="article"
        >
          <div>
            <template v-if="!error">
              <div v-html="$t('Desc')" class="mb-5"></div>
              <v-form ref="form" v-model="form.valid" lazy-validation>
                <v-text-field
                  v-model="form.username"
                  dense
                  outlined
                  required
                  :rules="[(v) => !!v || $t('UsernameMissing')]"
                  :disabled="form.is_saving"
                  :label="$t('EnterUsername')"
                  type="text"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      :loading="form.is_saving"
                      @click="addBlock"
                      style="margin-top: -7px"
                      class="text-none"
                      color="primary"
                      >{{ $t("Block") }}</v-btn
                    >
                  </template>
                </v-text-field>
                <error-inline :error="form.error"></error-inline>
              </v-form>

              <v-list
                max-width="400"
                min-width="300"
                class="mx-auto"
                v-if="items && items.length > 0"
              >
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-avatar>
                    <user-avatar :user="item.user" :size="40"></user-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      item.user.username | capitalize
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      :loading="item.is_deleting"
                      :disabled="item.is_deleting"
                      depressed
                      small
                      fab
                      @click="deleteItem(item)"
                    >
                      <v-icon small>$vuetify.icons.fasTrashAlt</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <div class="text-center mt-4 mb-10" v-if="unit_pages > 1">
                <v-pagination
                  v-model="current_page"
                  :length="unit_pages"
                  :total-visible="7"
                ></v-pagination>
              </div>
              <div v-if="!items || items.length < 1" class="text-center pt-7">
                {{ $t("NoBlocking") }}
              </div>
            </template>
            <error-box :error="error"></error-box>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Blocked members",
    "Username": "Username",
    "NoBlocking": "There is no blocked users to show!",
    "EnterUsername": "Enter username...",
    "Block": "Block",
    "SuccessCreate": "User blocked!",
    "ConfirmDeleteBlock": "Are you sure you want to unblock {username}?",
    "SuccessDelete": "User unblocked!",
    "UsernameMissing": "Username is missing!",
    "Desc": "Once you block someone, that user can no longer see things you post on your diary. The user can not read or write comments in your diary."
    },
    "sv": {
    "Title": "Blockerade användare",
    "Username": "Användarnamn",
    "NoBlocking": "Det finns inga blockerade användare att visa.",
    "EnterUsername": "Ange användarnamn...",
    "Block": "Blockera",
    "SuccessCreate": "Användaren blockerad!",
    "ConfirmDeleteBlock": "Är du säker på att du vill avblockera {username}?",
    "SuccessDelete": "Användare avblockerad!",
    "UsernameMissing": "Användarnamn saknas!",
    "Desc": "När du blockerar en användare kan användaren inte längre se saker som du lägger upp i din dagbok. Användaren kan inte heller läsa eller kommentera."
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { blogBlockService } from "@/_services";
import UserAvatar from "@/components/avatars/UserAvatar.vue";
export default {
  name: "diary_blog_admin_blocking",
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "user-avatar": UserAvatar,
  },
  data: () => ({
    is_loading: false,
    error: null,
    page: 1,
    unit_pages: 0,

    items: [],

    form: {
      username: "",
      error: null,
      is_saving: false,
      valid: false,
    },
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    this.setFromUrl();
    this.load();
  },
  methods: {
    load() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      blogBlockService
        .load(self.diary.name, self.page)
        .then(function (response) {
          self.items = response.data.data;
          self.is_loading = false;
          self.unit_pages = response.data.unit_pages;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    getTitle() {
      return this.$t("Title");
    },
    setFromUrl() {
      this.current_page = 1;

      if (this.$route.query.page)
        this.current_page = parseInt(this.$route.query.page);
    },
    addBlock() {
      var self = this;
      self.form.error = null;

      if (self.$refs.form.validate()) {
        self.form.is_saving = true;

        blogBlockService
          .add(self.diary.name, self.form.username)
          .then(function () {
            self.$successNoty(self.$t("SuccessCreate"));
            self.load();
            self.$refs.form.reset();
            self.form.is_saving = false;
          })
          .catch(function (error) {
            self.form.error = error;
            self.form.is_saving = false;
          });
      }
    },
    async deleteItem(item) {
      var self = this;

      const res = await self.$confirm(
        self.$t("ConfirmDeleteBlock", { username: item.user.username })
      );

      if (res) {
        self.$set(item, "is_deleting", true);

        blogBlockService
          .delete(item.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));
            self.items = self.items.filter((post) => post.id !== item.id);
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            item.is_deleting = false;
          });
      }
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.load();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.current_page) return;
      self.$router.push({ query: { page: self.current_page } });
    },
  },
};
</script>