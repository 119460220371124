<template>
  <v-container class="pa-0" v-if="is_admin">
    <v-card min-height="300" class="pb-10">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("DiarySettings") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-skeleton-loader :loading="page.is_loading" type="article">
          <div>
            <template v-if="!page.error">
              <v-form ref="profile_form" v-model="profile.form" lazy-validation>
                <v-text-field
                  :label="$t('Title')"
                  v-model="settings.title"
                  :disabled="profile.is_saving"
                  counter
                  maxlength="50"
                  :rules="[(v) => !!v || $t('TitleIsMissing')]"
                  outlined
                  required
                ></v-text-field>

                <v-select
                  :items="['kg', 'lbs']"
                  v-model="settings.weight_format"
                  :disabled="profile.is_saving"
                  required
                  :rules="[
                    () => !!settings.weight_format || $t('FieldRequired'),
                  ]"
                  outlined
                  @change="show_warning = true"
                  :label="$t('WeightFormat')"
                ></v-select>

                <v-select
                  :items="['cm', 'inch']"
                  v-model="settings.length_format"
                  :disabled="profile.is_saving"
                  required
                  :rules="[
                    () => !!settings.length_format || $t('FieldRequired'),
                  ]"
                  outlined
                  @change="show_warning = true"
                  :label="$t('LengthFormat')"
                ></v-select>

                <v-alert
                  type="info"
                  outlined
                  v-show="show_warning"
                  color="primary"
                  class="mb-7"
                  >{{ $t("Desc") }}</v-alert
                >

                <error-box :error="profile.error"></error-box>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span v-if="profile.is_success" class="success-text mr-4"
                    >{{ $t("Saved") }}!</span
                  >

                  <v-btn
                    :loading="profile.is_saving"
                    color="primary"
                    @click="saveProfile"
                    class="text-none"
                    >{{ $t("Button.Save") }}</v-btn
                  >
                </v-card-actions>
              </v-form>
            </template>
            <error-page :error="page.error" v-if="page.error"></error-page>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "DiarySettings": "Diary settings",
    "SuccessSaveSettings": "Privacy updated!",
    "Title": "Diary title",
    "TitleIsMissing": "Title is missing!",
    "LengthFormat": "Length format",
    "WeightFormat": "Weight format",
    "FieldRequired": "Required",
    "Saved": "Saved",
    "Desc": "When you change the format in which height and weight are to be displayed, already published records with the old value will not be updated. It will only apply to new posts."
    },
    "sv": {
    "DiarySettings": "Dagboksinställningar",
    "SuccessSaveSettings": "Inställningarna uppdaterades!",
    "Title": "Titel",
    "TitleIsMissing": "Titel måste anges",
    "LengthFormat": "Längdformat",
    "WeightFormat": "Viktformat",
    "FieldRequired": "Måste anges",
    "Saved": "Sparat",
    "Desc": "När du ändrar vilket format längd och vikt ska visas i kommer inte redan publicerade poster med det gamla värdet att uppdateras utan kommer enbart gälla nya poster."
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { diaryService } from "../../../../_services";

export default {
  name: "diary_settings_page",
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },

    settings: {},

    show_warning: false,

    profile: {
      form: true,
      is_saving: false,
      error: null,
      is_success: false,
    },
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    this.load();
  },
  methods: {
    ...mapActions({
      updateDiaryInfo: "diary/updateDiaryInfo",
    }),
    getTitle() {
      return this.$t("DiarySettings");
    },
    load() {
      var self = this;
      self.page.is_loading = true;

      diaryService
        .loadAdminDiary(self.diary.name)
        .then(function (response) {
          self.settings = response.data;
          self.page.is_loading = false;
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },
    saveProfile() {
      var self = this;
      self.profile.error = null;
      self.profile.is_success = false;

      if (self.$refs.profile_form.validate()) {
        self.profile.is_saving = true;

        self
          .updateDiaryInfo({
            title: self.settings.title,
            weight_format: self.settings.weight_format,
            length_format: self.settings.length_format,
          })
          .then(function () {
            self.profile.is_saving = false;
            self.profile.is_success = true;
          })
          .catch(function (error) {
            self.profile.error = error;
            self.profile.is_saving = false;
          });
      }
    },
  },
};
</script>