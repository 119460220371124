<template>
  <v-container class="diary-pages-page pa-0" v-if="is_admin">
    <v-card min-height="300">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("Title") }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
          :to="getUrl('/admin/diary/new')"
          class="text-none text-subtitle-1"
          text
          color="primary"
        >
          {{ $t("CreatePost") }}
        </v-btn>
        <template v-slot:extension>
          <v-tabs
            class="mobile-tabs-fix tab-border-all-fix"
            grow
            active-class="active-menu"
            hide-slider
          >
            <v-tab :to="getUrl('/admin/diary')" exact-path>
              <v-badge
                inline
                color="grey"
                class="text-none"
                :value="statistics.is_loaded"
                :content="getFormatedUnit(statistics.total)"
                >{{ $t("All") }}</v-badge
              >
            </v-tab>
            <v-tab :to="getUrl('/admin/diary/published')">
              <v-badge
                inline
                color="grey"
                class="text-none"
                :value="statistics.is_loaded"
                :content="getFormatedUnit(statistics.published)"
                >{{ $t("Published") }}</v-badge
              >
            </v-tab>
            <v-tab :to="getUrl('/admin/diary/saved')">
              <v-badge
                inline
                class="text-none"
                color="grey"
                :value="statistics.is_loaded"
                :content="getFormatedUnit(statistics.saved)"
                >{{ $t("Saved") }}</v-badge
              >
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text>
        <v-skeleton-loader :loading="page.is_loading" type="article">
          <div>
            <template v-if="!page.error">
              <template v-if="items && items.length > 0">
                <v-simple-table v-if="!$isMobile">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("Header") }}
                        </th>
                        <th class="text-left">{{ $t("Status") }}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in items" :key="item.id">
                        <td>
                          <router-link :to="{ name: 'diary_blog_edit', params: { id: item.id } }" class="link-color">
                            {{ item.header | capitalize }}</router-link
                          >
                        </td>
                        <td>{{ getStatus(item) }}</td>
                        <td class="text-right">
                          <post-menu
                            btn-list
                            v-on:deleted="onDeleted"
                            :post="item"
                          ></post-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-list two-line v-if="$isMobile">
                  <v-list-item
                    v-for="item in items"
                    :key="item.id"
                    class="pl-0 pr-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.header | capitalize
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getStatus(item) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <post-menu
                        btn-list
                        v-on:deleted="onDeleted"
                        :post="item"
                      ></post-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </template>
              <div class="text-center mt-4 mb-10" v-if="unit_pages > 1">
                <v-pagination
                  v-model="current_page"
                  :length="unit_pages"
                  circle
                  :total-visible="7"
                ></v-pagination>
              </div>

              <div v-if="!items || items.length < 1" class="text-center pt-7">
                {{ $t("NoPages") }}
              </div>
            </template>
            <error-box :error="page.error"></error-box>
          </div>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Diary posts",
    "CreatePost": "Create post",
    "Status": "Status",
    "Name": "Title",
    "NoPages": "There is no posts to show!",
    "SuccessDelete": "Post deleted!",
    "Published": "Published",
    "Saved": "Saved",
    "All": "All",
    "Header": "Title"
    },
    "sv": {
    "Title": "Dagboksinlägg",
    "CreatePost": "Skapa inlägg",
    "Status": "Status",
    "Name": "Titel",
    "NoPages": "Det finns inga inlägg att visa",
    "SuccessDelete": "Inlägget togs bort!",
    "Edit": "Redigera inlägg",
    "Published": "Publicerad",
    "Saved": "Sparad",
    "All": "All",
    "Header": "Rubrik"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { blogPostService } from "@/_services";
import BlogPostMenu from "@/components/diary/helpers/BlogPostMenu.vue";

export default {
  name: "diary_blog_admin_posts",
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "post-menu": BlogPostMenu,
  },
  data: () => ({
    page: {
      is_loading: false,
      error: null,
    },
    items: [],
    current_page: 1,
    unit_pages: 0,

    statistics: {
      total: 0,
      published: 0,
      saved: 0,
      is_loading: false,
    },
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }
    this.setFromUrl();
    this.load();
    this.loadStatistics();
  },
  methods: {
    ...mapActions({
      _delete: "diary/news/delete",
    }),
    setFromUrl() {
      this.current_page = 1;

      if (this.$route.query.page)
        this.current_page = parseInt(this.$route.query.page);
    },
    loadStatistics() {
      var self = this;
      self.statistics.error = null;
      self.statistics.is_loading = true;

      blogPostService
        .publishstatistics(self.diary.name)
        .then(function (response) {
          self.statistics.published = response.data.published;
          self.statistics.total = response.data.total;
          self.statistics.saved = response.data.saved;
          self.statistics.is_loading = false;
        })
        .catch(function (error) {
          self.statistics.error = error;
          self.statistics.is_loading = false;
        });
    },
    load() {
      var self = this;
      self.page.error = null;
      self.page.is_loading = true;

      var data = { page: self.current_page };

      var type = self.$route.params.type;

      if (type) {
        if (type == "published") {
          data["status"] = 1;
        } else if (type == "saved") {
          data["status"] = 0;
        }
      }

      blogPostService
        .searchPostList(self.diary.name, data)
        .then(function (response) {
          self.items = response.data.data;
          self.unit_pages = response.data.unit_pages;
          self.page.is_loading = false;
        })
        .catch(function (error) {
          self.page.error = error;
          self.page.is_loading = false;
        });
    },
    getTitle() {
      return this.$t("Title");
    },
    getStatus(item) {
      if (item.status == 1) return this.$t("Published");
      return this.$t("Saved");
    },
    getUrl(url) {
      return "/" + this.diary.name + url;
    },
    onDeleted() {
      this.$successNoty(this.$t("SuccessDelete"));
      this.load();
    },
    getFormatedUnit(value) {
      if (!value) return "0";
      if (value > 99) return "99+";
      else return value;
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.load();
    },
    current_page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.current_page) return;
      self.$router.push({ query: { page: self.current_page } });
    },
  },
};
</script>