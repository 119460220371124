<template>
  <v-container class="pa-0" v-if="is_admin">
    <v-card min-height="300" class="pb-10">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("Title") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-list class="pt-0 pb-0 menu-list" max-width="400px">
          <draggable
            v-model="menu_items"
            @start="isDragging = true"
            @end="isDragging = false"
            tag="div"
            :move="onMove"
            v-bind="dragOptions"
            handle=".handle"
          >
            <transition-group type="transition" :name="'flip-list'">
              <div
                v-for="item in menu_items"
                :key="item.key"
                class="sort-item"
                v-bind:class="{ fixed: !item.can_change_order }"
              >
                <v-list-item>
                  <v-list-item-icon class="handle">
                    <v-icon v-if="item.can_change_order" small
                      >$vuetify.icons.fasArrowsAltV</v-icon
                    >
                    <v-icon v-if="!item.can_change_order" small
                      >$vuetify.icons.fasHome</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content class="handle">
                    <v-list-item-title class="global-list-header mb-0">{{
                      item.name | capitalize
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action v-if="item.settings.active_status">
                    <v-switch
                      dense
                      v-model="item.is_active"
                      inset
                      :loading="item.is_saving"
                      :disabled="is_saving || item.is_saving"
                      @change="statusChange(item)"
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-action
                    v-if="item.settings_menu && item.settings_menu.length > 0"
                  >
                    <button-menu
                      icon
                      :items="getSettingMenu(item)"
                      btn-icon="$vuetify.icons.dotsVertical"
                    ></button-menu>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </transition-group>
          </draggable>
        </v-list>
      </v-card-text>
      <v-card-actions class="action">
        <v-btn
          :loading="is_saving"
          color="primary"
          @click="saveOrder"
          class="text-none"
          >{{ $t("MenuOrderSave") }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <blog-dialog ref="blogDialog"></blog-dialog>
    <feed-dialog ref="feedDialog"></feed-dialog>
    <guestbook-dialog ref="guestbookDialog"></guestbook-dialog>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Menu settings",
    "MenuOrderSaved": "Settings saved!",
    "MenuOrderSave": "Save menu order",
    "Settings": "Settings"
    },
    "sv": {
    "Title": "Menyinställningar",
    "MenuOrderSaved": "Inställningarna sparades!",
    "MenuOrderSave": "Spara meny ordningen",
    "Settings": "Inställningar"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import draggable from "vuedraggable";
import { menuHelpers } from "@/_helpers";
import BlogPostSettingsDialog from "@/components/diary/dialogs/BlogPostSettingsDialog.vue";
import TimelineSettingsDialog from "@/components/diary/dialogs/TimelineSettingsDialog.vue";
import GuestbookSettingsDialog from "@/components/diary/dialogs/GuestbookSettingsDialog.vue";

export default {
  name: "diary_blog_admin_menu",
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      settings: (state) => state.diary.settings,
      is_admin: (state) => state.diary.is_admin,
    }),
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: this.is_saving,
        ghostClass: "ghost",
      };
    },
  },
  components: {
    draggable,
    "blog-dialog": BlogPostSettingsDialog,
    "feed-dialog": TimelineSettingsDialog,
    "guestbook-dialog": GuestbookSettingsDialog,
  },
  data: () => ({
    is_saving: false,
    isDragging: false,
    menu_items: [],
  }),
  created: function () {
    if (!this.is_admin) {
      this.$router.push("/" + this.diary.name);
      return;
    }

    this.menu_items = menuHelpers.getAllWithCheck(this.settings, null, null);
  },
  methods: {
    ...mapActions({
      updateSettings: "diary/updateSettings",
    }),
    getTitle() {
      return this.$t("Title");
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !(relatedElement.can_change_order == false)) &&
        !(draggedElement.can_change_order == false)
      );
    },
    saveOrder() {
      var self = this;
      self.is_saving = true;

      var menu = [];

      self.menu_items.forEach((element) => {
        menu.push(element.key);
      });

      var data = {
        items: menu,
      };

      self
        .updateSettings({
          name: self.$enums.DIARYSETTINGS.Menu,
          data: data,
        })
        .then(function () {
          self.is_saving = false;
          self.$successNoty(self.$t("MenuOrderSaved"));
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          self.is_saving = false;
        });
    },
    statusChange(item) {
      this.$set(item, "is_saving", true);
      var self = this;

      self
        .updateSettings({
          name: item.settings.active_status,
          data: { is_active: item.is_active },
        })
        .then(function () {
          item.is_saving = false;
        })
        .catch(function (error) {
          self.$ajaxErrorNoty(error);
          item.is_saving = false;
          item.is_active = !item.is_active;
        });
    },
    getSettingMenu(item) {
      var self = this;

      var items = [];

      item.settings_menu.forEach((element) => {
        items.push({
          name: element.name,
          click: function () {
            self.showSettings(element.key);
          },
        });
      });

      return items;
    },
    showSettings(modelName) {
      if (modelName == this.$enums.DIARYSETTINGS.Feed) {
        this.$refs.feedDialog.open();
      } else if (modelName == this.$enums.DIARYSETTINGS.BlogPage) {
        this.$refs.blogDialog.open();
      } else if (modelName == this.$enums.DIARYSETTINGS.Guestbook) {
        this.$refs.guestbookDialog.open();
      }
    },
  },
};
</script>

<style scoped>
.menu-list {
  margin: auto;
}

.menu-list .v-list-item__icon {
  margin-right: 10px;
  margin-top: 21px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.sort-item {
  border: solid 1px lightgray;
}
.handle {
  cursor: move;
}

.sort-item:hover {
  background-color: #f6f6f6 !important;
}

.sort-item.fixed .handle {
  cursor: default;
}
.fixed.sort-item:hover {
  background-color: transparent !important;
}
.action button {
  margin: auto;
}
</style>